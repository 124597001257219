body {
  background: repeating-linear-gradient(-45deg, #202c42, #202c42 .5pt, #0000 0 40pt), repeating-linear-gradient(-45deg, #202c42, #202c42 .5pt, #0000 0 26pt), #192233;
  margin: 0;
  font-size: calc(12px + .35vw);
}

@media (width >= 801px) {
  body {
    font-size: 14.8px;
  }
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.container-main {
  grid-template-rows: 50px auto auto auto auto auto;
  grid-template-columns: 1fr minmax(auto, 800px) 1fr;
  display: grid;
}

.bar-links {
  grid-gap: calc(15px + 1vw);
  margin: calc(12px + .8vw) calc(15px + 3vw);
  display: grid;
}

.bar-links-left {
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: repeat(4, auto);
  justify-self: self-start;
}

.bar-links-right {
  grid-area: 1 / 2 / 2 / -1;
  grid-template-columns: repeat(2, auto);
  justify-self: self-end;
}

.bar-links > div > a {
  color: #fff;
  border-bottom: .5px solid #48a9ea;
  font-family: Quicksand, sans-serif;
  font-size: calc(1em + .35vw);
  font-weight: bold;
  text-decoration: none;
}

@media (width >= 1201px) {
  .bar-links > div > a {
    font-size: 19.6px;
  }
}

.logo {
  grid-area: 2 / 2 / 3 / 3;
  justify-self: center;
  max-width: 640px;
  margin: 40px;
}

.logo-bloom {
  filter: blur(20px);
  opacity: .3;
  z-index: -1;
}

.button-try {
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #24c85a;
  border-radius: 25px;
  grid-area: 3 / 2 / 4 / 3;
  justify-self: center;
  width: 160px;
  height: 50px;
  margin-top: -25px;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  text-decoration: none;
  box-shadow: 5px 5px 10px #0003;
}

.button-try:hover {
  background-color: #2fdb67;
}

.button-try:active {
  background-color: #24c85a;
}

.content {
  padding: 40px 20px;
}

.banner-features {
  z-index: -1;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 46%, #ffcc70 100%);
  grid-area: 3 / 1 / 4 / -1;
  overflow: visible;
}

.features {
  grid-area: 3 / 2 / 4 / -2;
}

.glyph-background {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  width: 0;
  height: 0;
  margin: 0;
  font-family: Space Mono, monospace;
  position: relative;
  overflow: visible;
}

.illustration {
  text-align: center;
  margin: 5em 0;
  display: grid;
}

.sample {
  margin-bottom: .3em;
  font-size: calc(1em + 6.8vw);
  font-weight: bold;
}

@media (width >= 801px) {
  .sample {
    font-size: 69.2px;
  }
}

.sample > span {
  display: inline-block;
}

.sample > span:hover {
  color: #765cf6;
  mix-blend-mode: multiply;
}

.jump {
  animation: .9s linear JumpAnimation;
}

@keyframes JumpAnimation {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  1.78% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -3.239, 0, 1);
  }

  3.5% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -5.384, 0, 1);
  }

  6.95% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -7.864, 0, 1);
  }

  10.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.033, 0, 1);
  }

  13.9% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.562, 0, 1);
  }

  20.85% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.914, 0, 1);
  }

  27.81% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -9.984, 0, 1);
  }

  44.34% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -10, 0, 1);
  }

  44.44% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -10, 0, 1);
  }

  45.67% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -5.717, 0, 1);
  }

  46.84% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.89, 0, 1);
  }

  48% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.366, 0, 1);
  }

  49.17% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 3.35, 0, 1);
  }

  50.51% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 2.375, 0, 1);
  }

  51.84% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, .623, 0, 1);
  }

  53.18% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.67, 0, 1);
  }

  54.45% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -1.05, 0, 1);
  }

  55.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.832, 0, 1);
  }

  57.12% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.198, 0, 1);
  }

  59.74% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, .329, 0, 1);
  }

  65.02% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.103, 0, 1);
  }

  70.3% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, .032, 0, 1);
  }

  75.64% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.01, 0, 1);
  }

  80.93% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, .003, 0, 1);
  }

  86.21% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -.001, 0, 1);
  }

  91.49% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  96.83% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.token {
  font-size: 1.6em;
}

.feature-grid {
  text-align: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-content: space-evenly;
  margin-top: 40px;
  display: grid;
}

.feature {
  margin: 0 20px;
}

.example {
  grid-area: 4 / 2 / 5 / -2;
}

.code-block {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #dde1f9;
  background-color: #253451;
  border-radius: 3px;
  padding: .8em 1.3em;
  display: block;
}

.ident {
  color: #51d3f6;
}

.string {
  color: #7ef69d;
}

.op {
  color: #ff926e;
}

.mod {
  color: #697bdb;
}

.comment {
  color: #a5adc6;
}

.banner-benchmark {
  z-index: -1;
  background-color: #2485cb;
  background-image: linear-gradient(67deg, #2485cb 0%, #08aeea 37%, #11dd80 100%);
  grid-area: 5 / 1 / 6 / -1;
  overflow: visible;
}

.benchmark {
  grid-area: 5 / 2 / 6 / -2;
}

.chart {
  grid-template-rows: repeat(3, 1fr) 1.5em;
  grid-template-columns: 6em repeat(4, 1fr);
  height: calc(17em + 5vw);
  display: grid;
}

@media (width >= 801px) {
  .chart {
    height: 291.317px;
  }
}

.chart-label {
  place-self: center self-end;
}

.chart-label > p {
  margin-right: .8em;
}

.chart-border {
  border-left: .5px dashed #fff;
  width: .5px;
}

.chart-border-1 {
  z-index: 1;
  border-left: .5px solid #fff;
  grid-area: 1 / 2 / -1 / 3;
}

.chart-border-2 {
  grid-area: 1 / 3 / -1 / 4;
}

.chart-border-3 {
  grid-area: 1 / 4 / -1 / 5;
}

.chart-bar {
  align-self: center;
  overflow: hidden;
}

.chart-bar > div {
  box-sizing: border-box;
  text-align: center;
  background-color: #1245ff;
  border-radius: 0 3px 3px 0;
  margin-left: -25%;
  padding-left: 25%;
}

.chart-bar > div > p {
  opacity: 0;
  margin: 0;
  padding: .3em;
  font-weight: bold;
  transition: opacity .3s ease-in-out;
}

.chart-bar > div:hover > p {
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.chart-bar-pest {
  grid-area: 1 / 2 / 2 / -1;
}

.chart-bar-pest > div {
  background-color: #ff007b;
  width: 40%;
}

.chart-bar-nom {
  grid-area: 2 / 2 / 3 / -1;
}

.chart-bar-nom > div {
  width: 61.6%;
}

.chart-bar-serde {
  grid-area: 3 / 2 / 4 / -1;
}

.chart-bar-serde > div {
  width: 72.2%;
}

.chart-bar-hidden {
  opacity: 0;
}

.chart-bar-bounce {
  animation-name: BounceAnimation, FadeInAnimation;
  animation-duration: 2.5s, .4s;
  animation-timing-function: linear, ease;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: .2s, .2s;
  animation-fill-mode: backwards;
  animation-timeline: auto, auto;
}

@keyframes FadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes BounceAnimation {
  0% {
    transform: matrix3d(.75, 0, 0, 0, 0, .8, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  1.4% {
    transform: matrix3d(.849, 0, 0, 0, 0, .879, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  2.7% {
    transform: matrix3d(.985, 0, 0, 0, 0, .988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  4% {
    transform: matrix3d(1.091, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  5.31% {
    transform: matrix3d(1.128, 0, 0, 0, 0, 1.102, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  6.81% {
    transform: matrix3d(1.09, 0, 0, 0, 0, 1.072, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  8.21% {
    transform: matrix3d(1.018, 0, 0, 0, 0, 1.015, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  9.61% {
    transform: matrix3d(.958, 0, 0, 0, 0, .966, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  11.01% {
    transform: matrix3d(.936, 0, 0, 0, 0, .949, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  13.91% {
    transform: matrix3d(.991, 0, 0, 0, 0, .993, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  16.72% {
    transform: matrix3d(1.032, 0, 0, 0, 0, 1.025, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  22.52% {
    transform: matrix3d(.984, 0, 0, 0, 0, .987, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  28.23% {
    transform: matrix3d(1.008, 0, 0, 0, 0, 1.006, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  33.93% {
    transform: matrix3d(.996, 0, 0, 0, 0, .997, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  39.64% {
    transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  45.35% {
    transform: matrix3d(.999, 0, 0, 0, 0, .999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  51.05% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  56.76% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  62.56% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  68.27% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  73.97% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  79.68% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  85.39% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  91.09% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  96.8% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}

.chart-value {
  opacity: .7;
  align-self: center;
  margin-left: .5em;
  font-size: .8em;
}

.chart-value-0 {
  grid-area: 5 / 2 / 6 / 3;
}

.chart-value-250 {
  grid-area: 5 / 3 / 6 / 4;
}

.chart-value-500 {
  grid-area: 5 / 4 / 6 / 5;
}

.editor {
  grid-area: 6 / 2 / 7 / -2;
}

.wide-editor {
  grid-area: 6 / 1 / 7 / 4;
  padding-top: 0;
  padding-bottom: 0;
}

.gutter:hover {
  cursor: col-resize;
}

.flex-editor {
  flex-direction: row;
  display: flex;
}

.output-wrapper {
  grid-template-rows: 100%;
  display: grid;
}

.editor-grid > div {
  margin-bottom: 20px;
}

.wide-input {
  grid-template-rows: 20fr 1fr;
  grid-template-columns: none !important;
}

.editor-input {
  background-color: #253451;
  border-radius: 3px;
  grid-template-columns: 4fr 1fr;
  display: grid;
}

.editor-input-text {
  background-color: initial;
  border-radius: 0;
}

.editor-input-select {
  font-variant-ligatures: none;
  text-align-last: center;
  background-blend-mode: soft-light;
  color: #fff;
  appearance: none;
  background: #3f4e6b url("dropdown.63679d15.svg") 90% no-repeat;
  border: 1.5px solid #57627a;
  border-radius: 0 3px 3px 0;
  font-family: Space Mono, monospace;
  font-size: 1em;
}

.editor-input-select:disabled {
  background-blend-mode: soft-light;
  color: #7a879c;
  background: #3a475f url("dropdown.63679d15.svg") 90% no-repeat;
}

.cm-s-pest.CodeMirror {
  font-variant-ligatures: none;
  color: #fff;
  background: #253451;
  border-radius: 3px;
  padding: .8em 1.3em;
  font-family: Space Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}

.cm-s-pest .CodeMirror-cursor {
  border-left: 1px solid #fff;
}

.cm-s-pest span.cm-comment {
  color: #a5adc6;
}

.cm-s-pest span.cm-operator {
  color: #ff926e;
}

.cm-s-pest span.cm-operator-2 {
  color: #697bdb;
}

.cm-s-pest span.cm-number {
  color: #aa759f;
}

.cm-s-pest span.cm-string {
  color: #7ef69d;
}

.cm-s-pest span.cm-constiable {
  color: #4ea8d8;
}

.cm-s-pest span.cm-constiable-2 {
  color: #fff;
}

.cm-s-pest span.cm-tag {
  color: #f6dc51;
}

.cm-s-pest span.cm-error {
  color: #b0b0b0;
  background: #ac4142;
}

.cm-s-pest .CodeMirror-placeholder {
  color: #999 !important;
}

.cm-s-pest .CodeMirror-lint-mark-error {
  background-image: none;
  border-bottom: 1px dotted #f9ff3d;
}

.CodeMirror-lint-tooltip {
  font-variant-ligatures: none !important;
  background-color: #fff !important;
  border: none !important;
  border-left: 2em solid #ff3d3d !important;
  border-radius: 3px !important;
  padding: .8em 1.3em .8em 0 !important;
  font-family: Space Mono, monospace !important;
  font-size: 1em !important;
  line-height: 1.5em !important;
  box-shadow: 5px 5px 10px #0003 !important;
}

.CodeMirror-lint-marker-error, .CodeMirror-lint-message-error {
  background-image: none !important;
}

h1 {
  color: #fff;
  margin: 1.2em 0;
  font-family: Quicksand, sans-serif;
  font-size: 2.1em;
}

h2 {
  color: #fff;
  margin: 1.2em 0;
  font-family: Quicksand, sans-serif;
  font-size: 1.8em;
  font-weight: lighter;
}

p, a {
  color: #fff;
  font-family: Quicksand, sans-serif;
  font-size: 1.15em;
  line-height: 1.65em;
}

code {
  font-variant-ligatures: none;
  color: #fff;
  font-family: Space Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}

button {
  color: #fff;
  text-align: center;
  background-color: #616e86;
  border: 0;
  border-radius: 2px;
  justify-self: center;
  margin-bottom: 20px;
  padding: .8em 1em;
  font-family: Quicksand, sans-serif;
  font-size: 1em;
  transition: width .4s ease-in-out;
  box-shadow: 5px 5px 10px #0003;
}

button + button {
  margin-left: 20px;
}

button:hover {
  background-color: #7b89a5;
}

button:active, button:disabled {
  background-color: #596375;
}

textarea {
  resize: none;
  font-variant-ligatures: none;
  color: #fff;
  background-color: #253451;
  border: none;
  border-radius: 3px;
  padding: .8em 1.3em;
  font-family: Space Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}
/*# sourceMappingURL=static.b08207f0.css.map */
